.login-container {
  background: url(/public/login-background.jpg);
  background-size: cover;
  height: 100%;
}

.firefly {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 0.4vw;
  height: 0.4vw;
  margin: -0.2vw 0 0 9.8vw;
  animation: ease 200s alternate infinite;
  pointer-events: none;
}
.firefly::before, .firefly::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform-origin: -10vw;
}
.firefly::before {
  background: black;
  opacity: 0.4;
  animation: drift ease alternate infinite;
}
.firefly::after {
  background: white;
  opacity: 0;
  box-shadow: 0 0 0vw 0vw yellow;
  animation: drift ease alternate infinite, flash ease infinite;
}

.firefly:nth-child(1) {
  animation-name: move1;
}
.firefly:nth-child(1)::before {
  animation-duration: 13s;
}
.firefly:nth-child(1)::after {
  animation-duration: 13s, 6951ms;
  animation-delay: 0ms, 6929ms;
}

@keyframes move1 {
  0% {
    transform: translateX(18vw) translateY(-46vh) scale(0.68);
  }
  4.347826087% {
    transform: translateX(-12vw) translateY(-45vh) scale(0.88);
  }
  8.6956521739% {
    transform: translateX(4vw) translateY(48vh) scale(0.78);
  }
  13.0434782609% {
    transform: translateX(-43vw) translateY(21vh) scale(0.57);
  }
  17.3913043478% {
    transform: translateX(40vw) translateY(44vh) scale(0.64);
  }
  21.7391304348% {
    transform: translateX(30vw) translateY(10vh) scale(0.7);
  }
  26.0869565217% {
    transform: translateX(-48vw) translateY(-17vh) scale(0.85);
  }
  30.4347826087% {
    transform: translateX(17vw) translateY(14vh) scale(0.46);
  }
  34.7826086957% {
    transform: translateX(-20vw) translateY(18vh) scale(0.43);
  }
  39.1304347826% {
    transform: translateX(34vw) translateY(39vh) scale(0.53);
  }
  43.4782608696% {
    transform: translateX(3vw) translateY(9vh) scale(0.68);
  }
  47.8260869565% {
    transform: translateX(4vw) translateY(-42vh) scale(0.76);
  }
  52.1739130435% {
    transform: translateX(-21vw) translateY(13vh) scale(0.9);
  }
  56.5217391304% {
    transform: translateX(20vw) translateY(19vh) scale(0.28);
  }
  60.8695652174% {
    transform: translateX(-19vw) translateY(-45vh) scale(0.57);
  }
  65.2173913043% {
    transform: translateX(-37vw) translateY(-31vh) scale(0.54);
  }
  69.5652173913% {
    transform: translateX(-31vw) translateY(-30vh) scale(0.34);
  }
  73.9130434783% {
    transform: translateX(-25vw) translateY(5vh) scale(0.4);
  }
  78.2608695652% {
    transform: translateX(43vw) translateY(18vh) scale(0.67);
  }
  82.6086956522% {
    transform: translateX(3vw) translateY(43vh) scale(0.7);
  }
  86.9565217391% {
    transform: translateX(-31vw) translateY(1vh) scale(0.92);
  }
  91.3043478261% {
    transform: translateX(-40vw) translateY(26vh) scale(0.51);
  }
  95.652173913% {
    transform: translateX(-34vw) translateY(26vh) scale(0.62);
  }
  100% {
    transform: translateX(-49vw) translateY(-19vh) scale(0.51);
  }
}
.firefly:nth-child(2) {
  animation-name: move2;
}
.firefly:nth-child(2)::before {
  animation-duration: 18s;
}
.firefly:nth-child(2)::after {
  animation-duration: 18s, 6579ms;
  animation-delay: 0ms, 1108ms;
}

@keyframes move2 {
  0% {
    transform: translateX(-29vw) translateY(27vh) scale(0.53);
  }
  5% {
    transform: translateX(31vw) translateY(-15vh) scale(0.89);
  }
  10% {
    transform: translateX(3vw) translateY(-31vh) scale(0.89);
  }
  15% {
    transform: translateX(-49vw) translateY(42vh) scale(0.52);
  }
  20% {
    transform: translateX(-14vw) translateY(27vh) scale(0.39);
  }
  25% {
    transform: translateX(5vw) translateY(-6vh) scale(0.32);
  }
  30% {
    transform: translateX(46vw) translateY(-35vh) scale(0.92);
  }
  35% {
    transform: translateX(24vw) translateY(-38vh) scale(0.64);
  }
  40% {
    transform: translateX(-49vw) translateY(14vh) scale(0.42);
  }
  45% {
    transform: translateX(-28vw) translateY(-15vh) scale(0.68);
  }
  50% {
    transform: translateX(-33vw) translateY(-10vh) scale(0.34);
  }
  55% {
    transform: translateX(-23vw) translateY(16vh) scale(0.38);
  }
  60% {
    transform: translateX(34vw) translateY(-48vh) scale(0.41);
  }
  65% {
    transform: translateX(3vw) translateY(49vh) scale(0.48);
  }
  70% {
    transform: translateX(16vw) translateY(6vh) scale(0.82);
  }
  75% {
    transform: translateX(49vw) translateY(-19vh) scale(0.92);
  }
  80% {
    transform: translateX(-33vw) translateY(-18vh) scale(0.79);
  }
  85% {
    transform: translateX(-1vw) translateY(-8vh) scale(0.41);
  }
  90% {
    transform: translateX(-45vw) translateY(-35vh) scale(0.65);
  }
  95% {
    transform: translateX(6vw) translateY(39vh) scale(0.54);
  }
  100% {
    transform: translateX(27vw) translateY(-33vh) scale(0.73);
  }
}
.firefly:nth-child(3) {
  animation-name: move3;
}
.firefly:nth-child(3)::before {
  animation-duration: 11s;
}
.firefly:nth-child(3)::after {
  animation-duration: 11s, 8402ms;
  animation-delay: 0ms, 4050ms;
}

@keyframes move3 {
  0% {
    transform: translateX(-27vw) translateY(-20vh) scale(0.27);
  }
  3.8461538462% {
    transform: translateX(-30vw) translateY(18vh) scale(0.61);
  }
  7.6923076923% {
    transform: translateX(-34vw) translateY(-35vh) scale(0.89);
  }
  11.5384615385% {
    transform: translateX(48vw) translateY(-12vh) scale(0.97);
  }
  15.3846153846% {
    transform: translateX(29vw) translateY(24vh) scale(0.54);
  }
  19.2307692308% {
    transform: translateX(13vw) translateY(16vh) scale(0.72);
  }
  23.0769230769% {
    transform: translateX(22vw) translateY(-46vh) scale(0.49);
  }
  26.9230769231% {
    transform: translateX(26vw) translateY(38vh) scale(0.43);
  }
  30.7692307692% {
    transform: translateX(-44vw) translateY(19vh) scale(0.98);
  }
  34.6153846154% {
    transform: translateX(39vw) translateY(-6vh) scale(1);
  }
  38.4615384615% {
    transform: translateX(-38vw) translateY(-20vh) scale(0.64);
  }
  42.3076923077% {
    transform: translateX(-14vw) translateY(-31vh) scale(0.7);
  }
  46.1538461538% {
    transform: translateX(14vw) translateY(4vh) scale(0.6);
  }
  50% {
    transform: translateX(-26vw) translateY(-20vh) scale(0.48);
  }
  53.8461538462% {
    transform: translateX(-41vw) translateY(9vh) scale(0.48);
  }
  57.6923076923% {
    transform: translateX(34vw) translateY(-24vh) scale(0.92);
  }
  61.5384615385% {
    transform: translateX(-3vw) translateY(11vh) scale(0.98);
  }
  65.3846153846% {
    transform: translateX(24vw) translateY(19vh) scale(0.8);
  }
  69.2307692308% {
    transform: translateX(26vw) translateY(-41vh) scale(0.86);
  }
  73.0769230769% {
    transform: translateX(14vw) translateY(-45vh) scale(0.95);
  }
  76.9230769231% {
    transform: translateX(18vw) translateY(-16vh) scale(0.36);
  }
  80.7692307692% {
    transform: translateX(-16vw) translateY(17vh) scale(0.5);
  }
  84.6153846154% {
    transform: translateX(13vw) translateY(24vh) scale(0.74);
  }
  88.4615384615% {
    transform: translateX(47vw) translateY(18vh) scale(1);
  }
  92.3076923077% {
    transform: translateX(42vw) translateY(-22vh) scale(0.44);
  }
  96.1538461538% {
    transform: translateX(30vw) translateY(3vh) scale(0.44);
  }
  100% {
    transform: translateX(13vw) translateY(-42vh) scale(0.97);
  }
}
.firefly:nth-child(4) {
  animation-name: move4;
}
.firefly:nth-child(4)::before {
  animation-duration: 16s;
}
.firefly:nth-child(4)::after {
  animation-duration: 16s, 6531ms;
  animation-delay: 0ms, 5376ms;
}

@keyframes move4 {
  0% {
    transform: translateX(-31vw) translateY(-40vh) scale(0.53);
  }
  5.2631578947% {
    transform: translateX(35vw) translateY(19vh) scale(0.83);
  }
  10.5263157895% {
    transform: translateX(30vw) translateY(34vh) scale(0.26);
  }
  15.7894736842% {
    transform: translateX(23vw) translateY(30vh) scale(0.68);
  }
  21.0526315789% {
    transform: translateX(23vw) translateY(-48vh) scale(0.32);
  }
  26.3157894737% {
    transform: translateX(-2vw) translateY(-47vh) scale(0.77);
  }
  31.5789473684% {
    transform: translateX(6vw) translateY(13vh) scale(0.94);
  }
  36.8421052632% {
    transform: translateX(-16vw) translateY(4vh) scale(0.54);
  }
  42.1052631579% {
    transform: translateX(-37vw) translateY(30vh) scale(0.63);
  }
  47.3684210526% {
    transform: translateX(-29vw) translateY(-35vh) scale(0.4);
  }
  52.6315789474% {
    transform: translateX(15vw) translateY(-10vh) scale(0.44);
  }
  57.8947368421% {
    transform: translateX(23vw) translateY(-25vh) scale(0.67);
  }
  63.1578947368% {
    transform: translateX(-17vw) translateY(37vh) scale(0.26);
  }
  68.4210526316% {
    transform: translateX(33vw) translateY(38vh) scale(0.74);
  }
  73.6842105263% {
    transform: translateX(4vw) translateY(-5vh) scale(0.67);
  }
  78.9473684211% {
    transform: translateX(-41vw) translateY(-2vh) scale(0.59);
  }
  84.2105263158% {
    transform: translateX(-37vw) translateY(-47vh) scale(0.67);
  }
  89.4736842105% {
    transform: translateX(7vw) translateY(-17vh) scale(0.52);
  }
  94.7368421053% {
    transform: translateX(-4vw) translateY(24vh) scale(0.98);
  }
  100% {
    transform: translateX(-5vw) translateY(29vh) scale(0.58);
  }
}
.firefly:nth-child(5) {
  animation-name: move5;
}
.firefly:nth-child(5)::before {
  animation-duration: 10s;
}
.firefly:nth-child(5)::after {
  animation-duration: 10s, 6623ms;
  animation-delay: 0ms, 1212ms;
}

@keyframes move5 {
  0% {
    transform: translateX(20vw) translateY(6vh) scale(0.51);
  }
  4.7619047619% {
    transform: translateX(-15vw) translateY(17vh) scale(0.41);
  }
  9.5238095238% {
    transform: translateX(-7vw) translateY(-11vh) scale(0.56);
  }
  14.2857142857% {
    transform: translateX(1vw) translateY(-21vh) scale(0.32);
  }
  19.0476190476% {
    transform: translateX(21vw) translateY(31vh) scale(0.74);
  }
  23.8095238095% {
    transform: translateX(-5vw) translateY(45vh) scale(0.79);
  }
  28.5714285714% {
    transform: translateX(-22vw) translateY(4vh) scale(0.47);
  }
  33.3333333333% {
    transform: translateX(-39vw) translateY(28vh) scale(0.48);
  }
  38.0952380952% {
    transform: translateX(-43vw) translateY(43vh) scale(0.6);
  }
  42.8571428571% {
    transform: translateX(35vw) translateY(50vh) scale(0.89);
  }
  47.619047619% {
    transform: translateX(-48vw) translateY(-38vh) scale(0.63);
  }
  52.380952381% {
    transform: translateX(-1vw) translateY(34vh) scale(0.76);
  }
  57.1428571429% {
    transform: translateX(33vw) translateY(-31vh) scale(0.36);
  }
  61.9047619048% {
    transform: translateX(12vw) translateY(38vh) scale(0.94);
  }
  66.6666666667% {
    transform: translateX(-37vw) translateY(-41vh) scale(0.54);
  }
  71.4285714286% {
    transform: translateX(17vw) translateY(-11vh) scale(0.63);
  }
  76.1904761905% {
    transform: translateX(23vw) translateY(24vh) scale(0.4);
  }
  80.9523809524% {
    transform: translateX(-47vw) translateY(-30vh) scale(0.52);
  }
  85.7142857143% {
    transform: translateX(-18vw) translateY(-36vh) scale(0.81);
  }
  90.4761904762% {
    transform: translateX(34vw) translateY(47vh) scale(0.86);
  }
  95.2380952381% {
    transform: translateX(-31vw) translateY(-10vh) scale(0.59);
  }
  100% {
    transform: translateX(28vw) translateY(50vh) scale(0.35);
  }
}
.firefly:nth-child(6) {
  animation-name: move6;
}
.firefly:nth-child(6)::before {
  animation-duration: 14s;
}
.firefly:nth-child(6)::after {
  animation-duration: 14s, 7673ms;
  animation-delay: 0ms, 6330ms;
}

@keyframes move6 {
  0% {
    transform: translateX(30vw) translateY(-19vh) scale(0.86);
  }
  3.8461538462% {
    transform: translateX(30vw) translateY(-22vh) scale(0.79);
  }
  7.6923076923% {
    transform: translateX(-34vw) translateY(-42vh) scale(0.57);
  }
  11.5384615385% {
    transform: translateX(28vw) translateY(-26vh) scale(0.75);
  }
  15.3846153846% {
    transform: translateX(-13vw) translateY(-48vh) scale(0.45);
  }
  19.2307692308% {
    transform: translateX(-35vw) translateY(23vh) scale(0.44);
  }
  23.0769230769% {
    transform: translateX(-29vw) translateY(-23vh) scale(0.59);
  }
  26.9230769231% {
    transform: translateX(-13vw) translateY(36vh) scale(0.26);
  }
  30.7692307692% {
    transform: translateX(38vw) translateY(-43vh) scale(0.65);
  }
  34.6153846154% {
    transform: translateX(-46vw) translateY(28vh) scale(0.65);
  }
  38.4615384615% {
    transform: translateX(0vw) translateY(-39vh) scale(0.58);
  }
  42.3076923077% {
    transform: translateX(-3vw) translateY(11vh) scale(0.4);
  }
  46.1538461538% {
    transform: translateX(-38vw) translateY(12vh) scale(0.74);
  }
  50% {
    transform: translateX(-45vw) translateY(44vh) scale(0.83);
  }
  53.8461538462% {
    transform: translateX(-47vw) translateY(-8vh) scale(0.96);
  }
  57.6923076923% {
    transform: translateX(21vw) translateY(-9vh) scale(0.62);
  }
  61.5384615385% {
    transform: translateX(-1vw) translateY(32vh) scale(0.74);
  }
  65.3846153846% {
    transform: translateX(31vw) translateY(-8vh) scale(0.93);
  }
  69.2307692308% {
    transform: translateX(35vw) translateY(50vh) scale(0.72);
  }
  73.0769230769% {
    transform: translateX(-27vw) translateY(-44vh) scale(0.42);
  }
  76.9230769231% {
    transform: translateX(-32vw) translateY(-24vh) scale(1);
  }
  80.7692307692% {
    transform: translateX(-43vw) translateY(-36vh) scale(0.5);
  }
  84.6153846154% {
    transform: translateX(49vw) translateY(40vh) scale(0.65);
  }
  88.4615384615% {
    transform: translateX(17vw) translateY(3vh) scale(0.62);
  }
  92.3076923077% {
    transform: translateX(-42vw) translateY(8vh) scale(0.46);
  }
  96.1538461538% {
    transform: translateX(15vw) translateY(-35vh) scale(0.53);
  }
  100% {
    transform: translateX(20vw) translateY(19vh) scale(0.97);
  }
}
.firefly:nth-child(7) {
  animation-name: move7;
}
.firefly:nth-child(7)::before {
  animation-duration: 18s;
}
.firefly:nth-child(7)::after {
  animation-duration: 18s, 5697ms;
  animation-delay: 0ms, 4821ms;
}

@keyframes move7 {
  0% {
    transform: translateX(15vw) translateY(-48vh) scale(0.96);
  }
  4.1666666667% {
    transform: translateX(-17vw) translateY(37vh) scale(0.72);
  }
  8.3333333333% {
    transform: translateX(-15vw) translateY(-36vh) scale(0.4);
  }
  12.5% {
    transform: translateX(37vw) translateY(-25vh) scale(0.53);
  }
  16.6666666667% {
    transform: translateX(-14vw) translateY(-22vh) scale(0.92);
  }
  20.8333333333% {
    transform: translateX(21vw) translateY(-7vh) scale(0.27);
  }
  25% {
    transform: translateX(39vw) translateY(-14vh) scale(0.36);
  }
  29.1666666667% {
    transform: translateX(-43vw) translateY(41vh) scale(0.55);
  }
  33.3333333333% {
    transform: translateX(-21vw) translateY(5vh) scale(0.69);
  }
  37.5% {
    transform: translateX(31vw) translateY(-48vh) scale(0.29);
  }
  41.6666666667% {
    transform: translateX(-22vw) translateY(8vh) scale(0.89);
  }
  45.8333333333% {
    transform: translateX(44vw) translateY(-19vh) scale(0.9);
  }
  50% {
    transform: translateX(-29vw) translateY(-49vh) scale(0.35);
  }
  54.1666666667% {
    transform: translateX(48vw) translateY(-13vh) scale(0.64);
  }
  58.3333333333% {
    transform: translateX(-8vw) translateY(9vh) scale(0.81);
  }
  62.5% {
    transform: translateX(2vw) translateY(-42vh) scale(0.32);
  }
  66.6666666667% {
    transform: translateX(-30vw) translateY(19vh) scale(0.7);
  }
  70.8333333333% {
    transform: translateX(-41vw) translateY(-21vh) scale(0.5);
  }
  75% {
    transform: translateX(-11vw) translateY(8vh) scale(0.84);
  }
  79.1666666667% {
    transform: translateX(23vw) translateY(44vh) scale(0.69);
  }
  83.3333333333% {
    transform: translateX(-47vw) translateY(37vh) scale(0.46);
  }
  87.5% {
    transform: translateX(29vw) translateY(22vh) scale(0.92);
  }
  91.6666666667% {
    transform: translateX(-2vw) translateY(19vh) scale(0.88);
  }
  95.8333333333% {
    transform: translateX(-41vw) translateY(9vh) scale(0.44);
  }
  100% {
    transform: translateX(6vw) translateY(23vh) scale(0.87);
  }
}
.firefly:nth-child(8) {
  animation-name: move8;
}
.firefly:nth-child(8)::before {
  animation-duration: 18s;
}
.firefly:nth-child(8)::after {
  animation-duration: 18s, 10161ms;
  animation-delay: 0ms, 4263ms;
}

@keyframes move8 {
  0% {
    transform: translateX(12vw) translateY(2vh) scale(0.81);
  }
  4.347826087% {
    transform: translateX(26vw) translateY(-26vh) scale(0.68);
  }
  8.6956521739% {
    transform: translateX(-10vw) translateY(-17vh) scale(0.39);
  }
  13.0434782609% {
    transform: translateX(35vw) translateY(-14vh) scale(0.37);
  }
  17.3913043478% {
    transform: translateX(31vw) translateY(3vh) scale(0.94);
  }
  21.7391304348% {
    transform: translateX(39vw) translateY(-12vh) scale(0.31);
  }
  26.0869565217% {
    transform: translateX(17vw) translateY(38vh) scale(0.32);
  }
  30.4347826087% {
    transform: translateX(-22vw) translateY(30vh) scale(0.8);
  }
  34.7826086957% {
    transform: translateX(-32vw) translateY(-44vh) scale(0.31);
  }
  39.1304347826% {
    transform: translateX(2vw) translateY(-38vh) scale(0.87);
  }
  43.4782608696% {
    transform: translateX(32vw) translateY(18vh) scale(0.36);
  }
  47.8260869565% {
    transform: translateX(3vw) translateY(5vh) scale(0.72);
  }
  52.1739130435% {
    transform: translateX(-31vw) translateY(-47vh) scale(0.53);
  }
  56.5217391304% {
    transform: translateX(-32vw) translateY(-7vh) scale(0.55);
  }
  60.8695652174% {
    transform: translateX(29vw) translateY(4vh) scale(0.74);
  }
  65.2173913043% {
    transform: translateX(24vw) translateY(2vh) scale(0.82);
  }
  69.5652173913% {
    transform: translateX(-4vw) translateY(-40vh) scale(0.3);
  }
  73.9130434783% {
    transform: translateX(-45vw) translateY(-14vh) scale(0.57);
  }
  78.2608695652% {
    transform: translateX(50vw) translateY(-6vh) scale(0.26);
  }
  82.6086956522% {
    transform: translateX(17vw) translateY(14vh) scale(0.49);
  }
  86.9565217391% {
    transform: translateX(-36vw) translateY(-30vh) scale(0.88);
  }
  91.3043478261% {
    transform: translateX(27vw) translateY(-48vh) scale(0.96);
  }
  95.652173913% {
    transform: translateX(4vw) translateY(0vh) scale(0.45);
  }
  100% {
    transform: translateX(-40vw) translateY(25vh) scale(0.49);
  }
}
.firefly:nth-child(9) {
  animation-name: move9;
}
.firefly:nth-child(9)::before {
  animation-duration: 11s;
}
.firefly:nth-child(9)::after {
  animation-duration: 11s, 8726ms;
  animation-delay: 0ms, 4070ms;
}

@keyframes move9 {
  0% {
    transform: translateX(42vw) translateY(-40vh) scale(0.92);
  }
  5.8823529412% {
    transform: translateX(-46vw) translateY(47vh) scale(0.64);
  }
  11.7647058824% {
    transform: translateX(-2vw) translateY(-40vh) scale(0.51);
  }
  17.6470588235% {
    transform: translateX(-30vw) translateY(-26vh) scale(0.45);
  }
  23.5294117647% {
    transform: translateX(39vw) translateY(48vh) scale(0.83);
  }
  29.4117647059% {
    transform: translateX(-31vw) translateY(-4vh) scale(0.99);
  }
  35.2941176471% {
    transform: translateX(10vw) translateY(-35vh) scale(0.74);
  }
  41.1764705882% {
    transform: translateX(-21vw) translateY(-31vh) scale(0.56);
  }
  47.0588235294% {
    transform: translateX(2vw) translateY(-41vh) scale(0.35);
  }
  52.9411764706% {
    transform: translateX(20vw) translateY(-39vh) scale(0.76);
  }
  58.8235294118% {
    transform: translateX(2vw) translateY(-34vh) scale(0.58);
  }
  64.7058823529% {
    transform: translateX(46vw) translateY(47vh) scale(0.36);
  }
  70.5882352941% {
    transform: translateX(-42vw) translateY(-29vh) scale(0.48);
  }
  76.4705882353% {
    transform: translateX(-42vw) translateY(42vh) scale(0.47);
  }
  82.3529411765% {
    transform: translateX(49vw) translateY(34vh) scale(0.73);
  }
  88.2352941176% {
    transform: translateX(-5vw) translateY(10vh) scale(0.63);
  }
  94.1176470588% {
    transform: translateX(-25vw) translateY(-47vh) scale(0.86);
  }
  100% {
    transform: translateX(-10vw) translateY(39vh) scale(0.99);
  }
}
.firefly:nth-child(10) {
  animation-name: move10;
}
.firefly:nth-child(10)::before {
  animation-duration: 17s;
}
.firefly:nth-child(10)::after {
  animation-duration: 17s, 5015ms;
  animation-delay: 0ms, 6475ms;
}

@keyframes move10 {
  0% {
    transform: translateX(7vw) translateY(-42vh) scale(0.67);
  }
  3.5714285714% {
    transform: translateX(26vw) translateY(5vh) scale(0.77);
  }
  7.1428571429% {
    transform: translateX(-13vw) translateY(-8vh) scale(0.47);
  }
  10.7142857143% {
    transform: translateX(-35vw) translateY(-26vh) scale(0.31);
  }
  14.2857142857% {
    transform: translateX(50vw) translateY(-25vh) scale(0.61);
  }
  17.8571428571% {
    transform: translateX(13vw) translateY(-45vh) scale(0.97);
  }
  21.4285714286% {
    transform: translateX(-14vw) translateY(-39vh) scale(0.89);
  }
  25% {
    transform: translateX(47vw) translateY(15vh) scale(0.47);
  }
  28.5714285714% {
    transform: translateX(21vw) translateY(27vh) scale(0.38);
  }
  32.1428571429% {
    transform: translateX(-36vw) translateY(2vh) scale(0.33);
  }
  35.7142857143% {
    transform: translateX(-15vw) translateY(-40vh) scale(0.54);
  }
  39.2857142857% {
    transform: translateX(-32vw) translateY(-46vh) scale(0.67);
  }
  42.8571428571% {
    transform: translateX(-2vw) translateY(-27vh) scale(0.93);
  }
  46.4285714286% {
    transform: translateX(-7vw) translateY(25vh) scale(1);
  }
  50% {
    transform: translateX(41vw) translateY(40vh) scale(0.54);
  }
  53.5714285714% {
    transform: translateX(43vw) translateY(31vh) scale(0.62);
  }
  57.1428571429% {
    transform: translateX(-26vw) translateY(-7vh) scale(0.52);
  }
  60.7142857143% {
    transform: translateX(38vw) translateY(11vh) scale(0.56);
  }
  64.2857142857% {
    transform: translateX(12vw) translateY(-11vh) scale(0.85);
  }
  67.8571428571% {
    transform: translateX(-5vw) translateY(-48vh) scale(0.95);
  }
  71.4285714286% {
    transform: translateX(48vw) translateY(-19vh) scale(0.71);
  }
  75% {
    transform: translateX(-3vw) translateY(-31vh) scale(0.78);
  }
  78.5714285714% {
    transform: translateX(-48vw) translateY(19vh) scale(0.77);
  }
  82.1428571429% {
    transform: translateX(36vw) translateY(28vh) scale(0.88);
  }
  85.7142857143% {
    transform: translateX(-3vw) translateY(36vh) scale(0.35);
  }
  89.2857142857% {
    transform: translateX(-1vw) translateY(-43vh) scale(0.67);
  }
  92.8571428571% {
    transform: translateX(-49vw) translateY(48vh) scale(0.81);
  }
  96.4285714286% {
    transform: translateX(29vw) translateY(7vh) scale(0.65);
  }
  100% {
    transform: translateX(8vw) translateY(0vh) scale(0.67);
  }
}
.firefly:nth-child(11) {
  animation-name: move11;
}
.firefly:nth-child(11)::before {
  animation-duration: 12s;
}
.firefly:nth-child(11)::after {
  animation-duration: 12s, 10238ms;
  animation-delay: 0ms, 7825ms;
}

@keyframes move11 {
  0% {
    transform: translateX(-13vw) translateY(-35vh) scale(0.99);
  }
  3.5714285714% {
    transform: translateX(-40vw) translateY(-8vh) scale(0.7);
  }
  7.1428571429% {
    transform: translateX(-4vw) translateY(29vh) scale(0.73);
  }
  10.7142857143% {
    transform: translateX(18vw) translateY(-2vh) scale(0.95);
  }
  14.2857142857% {
    transform: translateX(-6vw) translateY(-6vh) scale(0.47);
  }
  17.8571428571% {
    transform: translateX(3vw) translateY(33vh) scale(0.91);
  }
  21.4285714286% {
    transform: translateX(33vw) translateY(19vh) scale(0.73);
  }
  25% {
    transform: translateX(-18vw) translateY(12vh) scale(0.63);
  }
  28.5714285714% {
    transform: translateX(12vw) translateY(21vh) scale(0.61);
  }
  32.1428571429% {
    transform: translateX(32vw) translateY(29vh) scale(0.35);
  }
  35.7142857143% {
    transform: translateX(-15vw) translateY(49vh) scale(0.32);
  }
  39.2857142857% {
    transform: translateX(-41vw) translateY(-10vh) scale(0.81);
  }
  42.8571428571% {
    transform: translateX(19vw) translateY(-2vh) scale(0.95);
  }
  46.4285714286% {
    transform: translateX(-45vw) translateY(12vh) scale(0.88);
  }
  50% {
    transform: translateX(13vw) translateY(-7vh) scale(0.36);
  }
  53.5714285714% {
    transform: translateX(-48vw) translateY(43vh) scale(0.32);
  }
  57.1428571429% {
    transform: translateX(44vw) translateY(-48vh) scale(0.48);
  }
  60.7142857143% {
    transform: translateX(44vw) translateY(22vh) scale(0.95);
  }
  64.2857142857% {
    transform: translateX(-28vw) translateY(37vh) scale(0.35);
  }
  67.8571428571% {
    transform: translateX(14vw) translateY(14vh) scale(0.59);
  }
  71.4285714286% {
    transform: translateX(26vw) translateY(-25vh) scale(0.77);
  }
  75% {
    transform: translateX(-49vw) translateY(-47vh) scale(0.58);
  }
  78.5714285714% {
    transform: translateX(35vw) translateY(43vh) scale(0.59);
  }
  82.1428571429% {
    transform: translateX(-16vw) translateY(-33vh) scale(0.62);
  }
  85.7142857143% {
    transform: translateX(-44vw) translateY(-22vh) scale(0.59);
  }
  89.2857142857% {
    transform: translateX(25vw) translateY(6vh) scale(0.56);
  }
  92.8571428571% {
    transform: translateX(-30vw) translateY(-36vh) scale(0.81);
  }
  96.4285714286% {
    transform: translateX(-22vw) translateY(-1vh) scale(0.5);
  }
  100% {
    transform: translateX(31vw) translateY(2vh) scale(0.44);
  }
}
.firefly:nth-child(12) {
  animation-name: move12;
}
.firefly:nth-child(12)::before {
  animation-duration: 11s;
}
.firefly:nth-child(12)::after {
  animation-duration: 11s, 5300ms;
  animation-delay: 0ms, 6013ms;
}

@keyframes move12 {
  0% {
    transform: translateX(31vw) translateY(-9vh) scale(0.88);
  }
  5.8823529412% {
    transform: translateX(-34vw) translateY(-48vh) scale(0.29);
  }
  11.7647058824% {
    transform: translateX(-49vw) translateY(-48vh) scale(0.42);
  }
  17.6470588235% {
    transform: translateX(41vw) translateY(2vh) scale(0.7);
  }
  23.5294117647% {
    transform: translateX(-39vw) translateY(-46vh) scale(0.62);
  }
  29.4117647059% {
    transform: translateX(32vw) translateY(-16vh) scale(0.61);
  }
  35.2941176471% {
    transform: translateX(26vw) translateY(39vh) scale(0.53);
  }
  41.1764705882% {
    transform: translateX(-9vw) translateY(43vh) scale(0.92);
  }
  47.0588235294% {
    transform: translateX(35vw) translateY(-42vh) scale(0.85);
  }
  52.9411764706% {
    transform: translateX(-24vw) translateY(-34vh) scale(0.7);
  }
  58.8235294118% {
    transform: translateX(-35vw) translateY(-11vh) scale(0.5);
  }
  64.7058823529% {
    transform: translateX(-38vw) translateY(-21vh) scale(0.52);
  }
  70.5882352941% {
    transform: translateX(-33vw) translateY(-47vh) scale(0.35);
  }
  76.4705882353% {
    transform: translateX(13vw) translateY(18vh) scale(1);
  }
  82.3529411765% {
    transform: translateX(31vw) translateY(21vh) scale(0.62);
  }
  88.2352941176% {
    transform: translateX(-12vw) translateY(49vh) scale(0.78);
  }
  94.1176470588% {
    transform: translateX(7vw) translateY(-34vh) scale(0.39);
  }
  100% {
    transform: translateX(10vw) translateY(34vh) scale(0.62);
  }
}
.firefly:nth-child(13) {
  animation-name: move13;
}
.firefly:nth-child(13)::before {
  animation-duration: 17s;
}
.firefly:nth-child(13)::after {
  animation-duration: 17s, 7269ms;
  animation-delay: 0ms, 3230ms;
}

@keyframes move13 {
  0% {
    transform: translateX(-48vw) translateY(-48vh) scale(0.69);
  }
  5.2631578947% {
    transform: translateX(-30vw) translateY(-1vh) scale(0.43);
  }
  10.5263157895% {
    transform: translateX(30vw) translateY(39vh) scale(0.95);
  }
  15.7894736842% {
    transform: translateX(39vw) translateY(-14vh) scale(0.78);
  }
  21.0526315789% {
    transform: translateX(49vw) translateY(-31vh) scale(0.44);
  }
  26.3157894737% {
    transform: translateX(-24vw) translateY(-7vh) scale(0.26);
  }
  31.5789473684% {
    transform: translateX(-12vw) translateY(-38vh) scale(0.65);
  }
  36.8421052632% {
    transform: translateX(29vw) translateY(3vh) scale(1);
  }
  42.1052631579% {
    transform: translateX(-7vw) translateY(20vh) scale(0.5);
  }
  47.3684210526% {
    transform: translateX(30vw) translateY(-1vh) scale(0.91);
  }
  52.6315789474% {
    transform: translateX(-14vw) translateY(33vh) scale(0.86);
  }
  57.8947368421% {
    transform: translateX(13vw) translateY(26vh) scale(0.54);
  }
  63.1578947368% {
    transform: translateX(2vw) translateY(-38vh) scale(0.26);
  }
  68.4210526316% {
    transform: translateX(-18vw) translateY(-16vh) scale(0.79);
  }
  73.6842105263% {
    transform: translateX(-28vw) translateY(50vh) scale(0.82);
  }
  78.9473684211% {
    transform: translateX(47vw) translateY(-11vh) scale(0.94);
  }
  84.2105263158% {
    transform: translateX(39vw) translateY(43vh) scale(0.81);
  }
  89.4736842105% {
    transform: translateX(-18vw) translateY(8vh) scale(0.38);
  }
  94.7368421053% {
    transform: translateX(40vw) translateY(33vh) scale(0.35);
  }
  100% {
    transform: translateX(-32vw) translateY(-43vh) scale(0.37);
  }
}
.firefly:nth-child(14) {
  animation-name: move14;
}
.firefly:nth-child(14)::before {
  animation-duration: 18s;
}
.firefly:nth-child(14)::after {
  animation-duration: 18s, 5385ms;
  animation-delay: 0ms, 4014ms;
}

@keyframes move14 {
  0% {
    transform: translateX(46vw) translateY(11vh) scale(0.93);
  }
  4% {
    transform: translateX(46vw) translateY(4vh) scale(0.52);
  }
  8% {
    transform: translateX(-36vw) translateY(-15vh) scale(0.35);
  }
  12% {
    transform: translateX(40vw) translateY(-1vh) scale(0.33);
  }
  16% {
    transform: translateX(30vw) translateY(-40vh) scale(0.76);
  }
  20% {
    transform: translateX(30vw) translateY(0vh) scale(0.62);
  }
  24% {
    transform: translateX(-44vw) translateY(-35vh) scale(0.65);
  }
  28% {
    transform: translateX(-31vw) translateY(-30vh) scale(0.85);
  }
  32% {
    transform: translateX(30vw) translateY(22vh) scale(0.96);
  }
  36% {
    transform: translateX(47vw) translateY(16vh) scale(0.72);
  }
  40% {
    transform: translateX(43vw) translateY(28vh) scale(0.88);
  }
  44% {
    transform: translateX(50vw) translateY(29vh) scale(0.76);
  }
  48% {
    transform: translateX(24vw) translateY(-30vh) scale(0.34);
  }
  52% {
    transform: translateX(-34vw) translateY(-1vh) scale(0.57);
  }
  56% {
    transform: translateX(13vw) translateY(-4vh) scale(0.4);
  }
  60% {
    transform: translateX(34vw) translateY(14vh) scale(0.43);
  }
  64% {
    transform: translateX(8vw) translateY(-37vh) scale(0.54);
  }
  68% {
    transform: translateX(-18vw) translateY(-15vh) scale(0.4);
  }
  72% {
    transform: translateX(42vw) translateY(-14vh) scale(0.6);
  }
  76% {
    transform: translateX(-37vw) translateY(9vh) scale(0.47);
  }
  80% {
    transform: translateX(0vw) translateY(10vh) scale(0.49);
  }
  84% {
    transform: translateX(40vw) translateY(44vh) scale(0.42);
  }
  88% {
    transform: translateX(-8vw) translateY(-23vh) scale(0.49);
  }
  92% {
    transform: translateX(-10vw) translateY(15vh) scale(0.96);
  }
  96% {
    transform: translateX(27vw) translateY(-2vh) scale(0.9);
  }
  100% {
    transform: translateX(-37vw) translateY(-22vh) scale(0.37);
  }
}
.firefly:nth-child(15) {
  animation-name: move15;
}
.firefly:nth-child(15)::before {
  animation-duration: 14s;
}
.firefly:nth-child(15)::after {
  animation-duration: 14s, 10390ms;
  animation-delay: 0ms, 6999ms;
}

@keyframes move15 {
  0% {
    transform: translateX(-3vw) translateY(42vh) scale(0.95);
  }
  5.2631578947% {
    transform: translateX(-23vw) translateY(-36vh) scale(0.33);
  }
  10.5263157895% {
    transform: translateX(29vw) translateY(-44vh) scale(1);
  }
  15.7894736842% {
    transform: translateX(35vw) translateY(-28vh) scale(0.54);
  }
  21.0526315789% {
    transform: translateX(42vw) translateY(9vh) scale(0.35);
  }
  26.3157894737% {
    transform: translateX(-7vw) translateY(-41vh) scale(0.82);
  }
  31.5789473684% {
    transform: translateX(15vw) translateY(13vh) scale(0.89);
  }
  36.8421052632% {
    transform: translateX(-4vw) translateY(18vh) scale(0.84);
  }
  42.1052631579% {
    transform: translateX(29vw) translateY(-2vh) scale(0.56);
  }
  47.3684210526% {
    transform: translateX(-37vw) translateY(1vh) scale(0.54);
  }
  52.6315789474% {
    transform: translateX(27vw) translateY(10vh) scale(0.9);
  }
  57.8947368421% {
    transform: translateX(-15vw) translateY(39vh) scale(0.66);
  }
  63.1578947368% {
    transform: translateX(1vw) translateY(-3vh) scale(0.65);
  }
  68.4210526316% {
    transform: translateX(32vw) translateY(-7vh) scale(0.38);
  }
  73.6842105263% {
    transform: translateX(39vw) translateY(-43vh) scale(0.67);
  }
  78.9473684211% {
    transform: translateX(31vw) translateY(10vh) scale(0.54);
  }
  84.2105263158% {
    transform: translateX(33vw) translateY(-2vh) scale(0.59);
  }
  89.4736842105% {
    transform: translateX(-3vw) translateY(28vh) scale(0.98);
  }
  94.7368421053% {
    transform: translateX(10vw) translateY(-47vh) scale(0.74);
  }
  100% {
    transform: translateX(-29vw) translateY(50vh) scale(0.72);
  }
}
@keyframes drift {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes flash {
  0%, 30%, 100% {
    opacity: 0;
    box-shadow: 0 0 0vw 0vw yellow;
  }
  5% {
    opacity: 1;
    box-shadow: 0 0 2vw 0.4vw yellow;
  }
}